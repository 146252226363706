import axios from "axios";
import { URLSearchParams } from "url";

export const sleep = (time: number) =>
    new Promise((res) => setTimeout(res, time));
  
  export const calculateWindowSize = (windowWidth: number) => {
    if (windowWidth >= 1200) {
      return 'lg';
    }
    if (windowWidth >= 992) {
      return 'md';
    }
    if (windowWidth >= 768) {
      return 'sm';
    }
    return 'xs';
  };

export const authLogin = (userName: string, password: string) => {
  return new Promise(async (resolve, reject) => {
    await sleep(500);

    const apiURL = `${process.env.REACT_APP_LLM_API_URL}/token`;
    const data = new FormData();
    data.append('username', userName);
    data.append('password', password);
 
    try {
      const response = await axios.post(apiURL, data);
      console.log(response)

      if (response?.status === 200) {
        const profile = {
          ...response?.data
        };

        sessionStorage.setItem(
          'authentication',
          JSON.stringify({ profile })
        );
        resolve({ profile });
      } else {
        reject({ message: 'Invalid response from server' });
      }
    } catch (error) {
      reject({ message: 'Error occurred while authenticating' });
    }
  });
};

  
  
  export const getAuthStatus = () => {
    return new Promise(async (res, rej) => {
      await sleep(500);
      try {
        let authentication = localStorage.getItem('authentication');
        if (authentication) {
          authentication = JSON.parse(authentication);
          return res(authentication);
        }
        return res(undefined);
      } catch (error) {
        return res(undefined);
      }
    });
  };
  

