import { DeleteIcon, EditIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';
import UploadTagMultiSelect from '../admin/UploadTagMultiSelect';

const AdminFileList = () => {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);

  const [options, setOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectTags, setSelectTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState('');
  const toast = useToast();
  const cancelRef = useRef();

  const { login, getToken } = useAuth();
  const history = useHistory();

  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [showTextbox, setShowTextbox] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const inputTextColor = useColorModeValue('black', 'white');

  const handleAddDisplayNameClick = () => {
    setShowTextbox(true);
  };

  const fetchPartners = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-partners`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      const filteredPartners = response.data.filter(
        (partner: any) =>
          partner.name === 'state_banking_compliance' ||
          partner.name === 'federal_banking_compliance'
      );
      setPartners(filteredPartners);
    } catch (error) {
      console.error('Error fetching partners:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching partners.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
    }
  };

  const fetchPersonas = async (partnerId: number) => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-personas/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setPersonas(response.data);
    } catch (error) {
      console.error('Error fetching personas:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching personas.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
    }
  };
  const handleSelectionChange = (newSelectedOptions: string[]) => {
    // setSelectedOptions(newSelectedOptions);
    console.log('Get new data on select', newSelectedOptions);
    setSelectTags(newSelectedOptions);
  };

  const authorizeAndGetOptions = async (
    partnerName: string,
    personaName: string
  ) => {
    setLoading(true);
    try {
      const token = await getToken();
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/get-files-list?partner=${partnerName}&persona=${personaName}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const filenamesArray = apiResponse.data.filenames.map((file: any) => ({
        file_name: file.file_name,
        upload_time: file.upload_time,
        display_name: file.display_name,
        last_modified_time: file.last_modified_time,
      }));
      setOptions(filenamesArray);
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handleUpload = async () => {
    if (uploadedFile && selectedPartner && selectedPersona) {
      try {
        setUploading(true);

        const formData = new FormData();
        formData.append('pdf_file', uploadedFile);

        const token = await getToken();
        const response = await axios.post(
          `${
            process.env.REACT_APP_LLM_API_URL
          }/upload-file-to-azure?partner_name=${selectedPartnerName}&persona_name=${
            selectedPersona.name
          }&user_id=${profile?.userId}&tags=${JSON.stringify(selectTags)}${
            displayName && displayName.length > 0
              ? `&display_name=${displayName}`
              : ''
          }`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data === true) {
          setUploadedFile(null);
          toast({
            title: 'Upload Successful',
            description: 'The file has been successfully uploaded.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
          authorizeAndGetOptions(selectedPartner.name, selectedPersona.name);
        } else {
          toast({
            title: 'Upload Failed',
            description: 'Something went wrong.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Upload Failed',
          description: 'Failed to upload the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setUploading(false);
      }
    } else {
      console.log('No file selected or no partner/persona selected.');
    }
  };

  const handleOpenDeleteAlert = (fileName: any) => {
    setFileToDelete(fileName);
    setIsDeleteAlertOpen(true);
  };

  const handleCloseDeleteAlert = () => {
    setIsDeleteAlertOpen(false);
  };

  const handleConfirmDelete = async () => {
    handleCloseDeleteAlert();
    await handleDeleteFile(fileToDelete);
  };

  const handleDeleteFile = async (fileName: any) => {
    try {
      const requestBody = {
        partner: selectedPartnerName,
        persona: selectedPersona.name,
        filename: fileName,
        auth: profile?.user?.sub,
      };

      const token = await getToken();
      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );

      if (response.data.delete === true) {
        toast({
          title: 'File Deleted',
          description: `The file ${fileToDelete} has been successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
        authorizeAndGetOptions(selectedPartnerName, selectedPersona.name);
      } else {
        toast({
          title: 'Delete Failed',
          description: 'Failed to delete the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      toast({
        title: 'Delete Failed',
        description: 'Failed to delete the file. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
    }
  };

  const handleFileClick = (fileName: any) => {
    console.log(`/chunk&fileName=${fileName}&personaId=${selectedPersona.id}`);
    history.push(
      `/file/chunk/${selectedPartnerName}/${selectedPersona.name}/${selectedPersona.id}/${fileName}`
    );
  };

  const handleFileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const handlePersonaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    console.log('Selected ID:', selectedId);
    console.log('Personas array:', personas);

    // Convert selectedId to string for comparison
    const selectedPersonaObj = personas.find(
      (persona: any) => persona.id.toString() === selectedId
    );

    console.log('Selected Persona Object:', selectedPersonaObj);

    if (selectedPersonaObj) {
      console.log('Found Selected Persona:', selectedPersonaObj);

      setSelectedPersona({
        id: selectedPersonaObj.id,
        name: selectedPersonaObj.name,
      });
      fetchOptions(selectedPersonaObj.id);
    } else {
      console.log('Selected persona not found!');
    }
  };

  const fetchOptions = async (id: number) => {
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_LLM_API_URL}/get-persona-tags/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data: any = await response.json();

      const fetchedTags = data.map((tag: any) => ({
        value: tag.tag,
        label: tag.tag_type_name,
      }));
      console.log('=====================FETCHED TAGS');
      console.log(fetchedTags);
      setTagOptions(fetchedTags);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  useEffect(() => {
    if (selectedPartner) {
      const selectedPartnerObj = partners.find(
        (partner) => partner.id === parseInt(selectedPartner)
      );
      if (selectedPartnerObj) {
        fetchPersonas(selectedPartnerObj.id);
        setSelectedPartnerName(selectedPartnerObj.name);
      }
    }
  }, [selectedPartner]);

  useEffect(() => {
    if (selectedPartner && selectedPersona) {
      const selectedPartnerObj = partners.find(
        (partner) => partner.id === parseInt(selectedPartner)
      );
      authorizeAndGetOptions(selectedPartnerObj.name, selectedPersona.name);
    }
  }, [selectedPartner, selectedPersona]);

  useEffect(() => {
    if (!profile?.idToken || !profile?.userId) {
      login();
    }
    if (!profile?.isAdmin) {
      console.log('NOT ADMIN');
    } else {
      fetchPartners();
      console.log('ADMIN');
    }
  }, []);

  return (
    <Box p={8} maxW="7xl" mx="auto" mt={{ xl: '50px' }}>
      {profile ? (
        !profile.isAdmin ? (
          <Flex justifyContent="center" alignItems="center" minH="200px">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              maxW="400px"
              borderRadius="lg"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="lg">
                Access Denied
              </AlertTitle>
              <AlertDescription maxWidth="sm" mb={4}>
                You do not have permission to access this page.
              </AlertDescription>
              <Button
                colorScheme="blue"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </Button>
            </Alert>
          </Flex>
        ) : (
          <>
            <FormControl mb={4}>
              <FormLabel htmlFor="partner-select" fontWeight="bold">
                Select Partner
              </FormLabel>
              <Select
                id="partner-select"
                placeholder="Select partner"
                onChange={(e) => {
                  setSelectedPartner(e.target.value);
                }}
                mb={4}
              >
                {partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.display_name || partner.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            {selectedPartner && (
              <FormControl mb={4}>
                <FormLabel htmlFor="persona-select" fontWeight="bold">
                  Select Persona
                </FormLabel>
                <Select
                  id="persona-select"
                  placeholder="Select persona"
                  onChange={handlePersonaChange}
                  mb={4}
                >
                  {personas.map((persona) => (
                    <option key={persona.id} value={persona.id}>
                      {persona.display_name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedPartner && selectedPersona && (
              <>
                <FormControl mb={4}>
                  <FormLabel htmlFor="file-drop" fontWeight="bold">
                    Drop or Select a File
                  </FormLabel>
                  <Box
                    id="file-drop"
                    border="2px dashed"
                    borderRadius="10px"
                    borderColor="gray.300"
                    minH="250px"
                    h="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}
                    cursor="pointer"
                  >
                    {uploadedFile ? (
                      <Box p={4}>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                        >
                          <Text fontWeight="bold">Selected File:</Text>
                          <Text>{uploadedFile.name}</Text>
                          {uploading ? (
                            <Spinner size="xl" />
                          ) : (
                            <>
                              <SimpleGrid columns={2} spacing={4} mt={4}>
                                <UploadTagMultiSelect
                                  selectedOptions={[]}
                                  onSelectionChange={handleSelectionChange}
                                  isEditing={true}
                                  options={tagOptions}
                                  setOptions={setOptions}
                                />
                              </SimpleGrid>
                              {/* <UploadTagMultiSelect
                                selectedOptions={[]}
                                onSelectionChange={handleSelectionChange}
                                isEditing={true}
                                options={tagOptions}
                                setOptions={setOptions}
                              /> */}
                              {!showTextbox && (
                                <Button
                                  mt={3}
                                  colorScheme="blue"
                                  onClick={handleAddDisplayNameClick}
                                  width="200px"
                                >
                                  Add Display Name
                                </Button>
                              )}
                              {showTextbox && (
                                <Input
                                  mt={3}
                                  placeholder="Enter Display Name"
                                  value={displayName}
                                  onChange={(e) =>
                                    setDisplayName(e.target.value)
                                  }
                                  width="200px"
                                  color={inputTextColor}
                                />
                              )}
                              <Button
                                mt={3}
                                colorScheme="blue"
                                onClick={handleUpload}
                                width="200px"
                              >
                                Upload File to Index
                              </Button>
                            </>
                          )}
                        </Flex>
                      </Box>
                    ) : (
                      <Text>Select or drop a file here</Text>
                    )}
                  </Box>
                  <input
                    type="file"
                    accept=".txt,.pdf,.doc,.docx"
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                  />
                </FormControl>

                {loading ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    minH="200px"
                  >
                    <Spinner size="xl" />
                  </Flex>
                ) : (
                  <>
                    {options.length > 0 ? (
                      <Text fontWeight="bold" mb={1} fontSize="lg">
                        Total Files: {options.length}
                      </Text>
                    ) : (
                      <Text fontWeight="bold" mb={1} fontSize="lg">
                        No files available.
                      </Text>
                    )}
                    {options.map((option, index) => (
                      <Flex
                        key={index}
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                      >
                        <Box
                          onClick={() => handleFileClick(option.file_name)}
                          cursor="pointer"
                          flex="1"
                        >
                          <Text>{option.display_name}</Text>
                        </Box>
                        <Flex alignItems="center">
                          {option.last_modified_time && (
                            <Text fontSize="sm" color="gray.500" mr={4}>
                              {new Date(
                                option.last_modified_time
                              ).toLocaleString()}
                            </Text>
                          )}
                          <Tooltip label="Edit Name" aria-label="Edit tooltip">
                            <Box
                              as={EditIcon}
                              ml={2}
                              color="white"
                              cursor="pointer"
                              onClick={() => console.log('Edit Name')}
                              boxSize={8}
                              borderRadius="50%"
                              p={2}
                              _hover={{ bg: 'blue.600' }}
                            />
                          </Tooltip>
                          <Tooltip
                            label="Show Details"
                            aria-label="Show Details tooltip"
                          >
                            <Box
                              as={InfoOutlineIcon}
                              color="white"
                              cursor="pointer"
                              onClick={() => handleFileClick(option.file_name)}
                              boxSize={8}
                              ml={2}
                              borderRadius="50%"
                              p={2}
                              _hover={{ bg: 'blue.600' }}
                            />
                          </Tooltip>
                          <Tooltip
                            label="Delete"
                            aria-label="Delete tooltip"
                            hasArrow
                            bg="red.500"
                          >
                            <Box
                              as={DeleteIcon}
                              color="white"
                              cursor="pointer"
                              onClick={() =>
                                handleOpenDeleteAlert(option.file_name)
                              }
                              ml={2}
                              boxSize={8}
                              borderRadius="50%"
                              p={2}
                              _hover={{ bg: 'red.600' }}
                            />
                          </Tooltip>
                        </Flex>
                      </Flex>
                    ))}
                  </>
                )}
              </>
            )}

            <AlertDialog
              isOpen={isDeleteAlertOpen}
              leastDestructiveRef={cancelRef}
              onClose={handleCloseDeleteAlert}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete File
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Are you sure you want to delete the file {fileToDelete}?
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={handleCloseDeleteAlert}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={handleConfirmDelete}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )
      ) : null}
    </Box>
  );
};

export default AdminFileList;
