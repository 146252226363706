import { Box, Flex, Stack } from '@chakra-ui/react';
import Brand from 'components/sidebarAdmin/components/Brand';
import Links from 'components/sidebarAdmin/components/Links';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

function SidebarContent(props: any) {
  const { routes } = props;
  const { getUserActiveSubscriptions } = useAuth(); // Assuming you have a hook to fetch user subscriptions
  const profile = useSelector((state: RootState) => state.auth.authentication);

  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const delay = 1000; // 3 seconds

    const timer = setTimeout(async () => {
      // console.log('profile?.userId', profile?.userId);

      if (profile?.userId) {
        try {
          const user_plans = await getUserActiveSubscriptions(profile?.userId);
          const hasPremiumPlan = user_plans.some(
            (plan: any) => plan.plan_name === 'Premium'
          );
          if (hasPremiumPlan) {
            // console.log('HAS premium', hasPremiumPlan);
            setShowModal(false);
          }
        } catch (error) {
          console.error('Error fetching user active subscriptions:', error);
        }
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [getUserActiveSubscriptions, profile]);
  // console.log('ROUTES from content');
  // console.log(routes);

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      borderRadius="30px"
      maxW="300px"
      px="20px"
    >
      <Brand />
      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }}>
          <Links routes={routes} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
