import { Flex, Stack, useColorModeValue } from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SEOComponent from 'seo';
import { RootState } from 'store/store';
import Pack from './components/Pack'; // Assuming this is your custom Pack component

function Pricing() {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [userActiveSubscriptions, setUserActiveSubscriptions] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const plans = useSelector((state: RootState) => state.plan.plans);
  const { getUserActiveSubscriptions, fetchSubscriptionPlan } = useAuth();

  const fetchPlans = useCallback(async () => {
    try {
      let fetchedPlans = plans;

      if (!plans) {
        fetchedPlans = await fetchSubscriptionPlan();
        setSubscriptionPlans(fetchedPlans || []);
      } else {
        setSubscriptionPlans(plans);
      }

      if (profile?.idToken && profile?.userId) {
        const userPlans = await getUserActiveSubscriptions(profile.userId);
        setUserActiveSubscriptions(userPlans);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [profile]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const determineButtonProps = (plan: any) => {
    const isActive = userActiveSubscriptions.some(
      (subscription) => subscription.plan_id === plan.plan_id
    );
    const hasPremiumSubscription = userActiveSubscriptions.some(
      (subscription) => subscription.plan_name.includes('Premium')
    );

    if (plan.contact_us) {
      return { buttonText: 'Contact Us', buttonClicked: true };
    }

    if (plan.plan_name.includes('Premium')) {
      if (process.env.REACT_APP_NODE_ENV === 'production') {
        return { buttonText: 'Coming Soon', buttonClicked: false };
      }
      return {
        buttonText: isActive ? 'Selected' : 'Upgrade',
        buttonClicked: !isActive,
      };
    }

    if (plan.plan_name.includes('Basic')) {
      if (hasPremiumSubscription) {
        return { buttonText: 'Downgrade', buttonClicked: true };
      }
      return {
        buttonText: isActive ? 'Selected' : 'Upgrade',
        buttonClicked: !isActive,
      };
    }

    return {
      buttonText: isActive ? 'Selected' : 'Upgrade',
      buttonClicked: !isActive,
    };
  };

  return (
    <>
      <SEOComponent
        title="Nucomply-Pricing"
        description="Banking Compliance Expert Package Pricing."
        canonical="/compliance/pricing"
      />
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Stack
          direction={{ sm: 'column', xl: 'row' }}
          alignItems="flex-end"
          spacing="20px"
          mt={{ base: '0px', md: '50px', xl: '100px' }}
          mb={{ base: '0px', md: '80px', xl: '160px' }}
        >
          {subscriptionPlans.map((plan) => {
            const { buttonText, buttonClicked } = determineButtonProps(plan);
            return (
              <Pack
                key={plan.plan_id}
                title={plan.plan_display_name}
                desc={
                  plan.plan_name.includes('Basic')
                    ? ''
                    : plan.contact_us
                    ? 'Contact Sales'
                    : `Subscribe for $${plan.price}/${plan.subscription_type}`
                }
                button={buttonText}
                planId={plan.plan_id}
                benefits={plan.features}
                clickButton={buttonClicked}
              />
            );
          })}
        </Stack>
      </Flex>
    </>
  );
}

export default Pricing;
