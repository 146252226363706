import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAuthentication } from 'store/reducers/auth';
import { resetChatHistory, setChatHistory } from 'store/reducers/chatHistory';
import { setPlans } from 'store/reducers/subscriptionPlan';
import { setUserProduct } from 'store/reducers/userProduct';
import { RootState } from 'store/store';

const useAuth = () => {
  const [idToken, setIdToken] = useState('');
  const dispatch = useDispatch();
  const { loginWithRedirect, getAccessTokenSilently, logout, user } =
    useAuth0();
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const chat = useSelector((state: RootState) => state.history.chat);

  const toast = useToast();

  useEffect(() => {
    const getAuthenticationData = () => {
      const authenticationDataString = localStorage.getItem('authentication');
      const productDataString = sessionStorage.getItem('product');
      const subscriptionPlanString = sessionStorage.getItem('plan');
      const chatHistoryString = sessionStorage.getItem('chat');

      if (authenticationDataString) {
        try {
          const authenticationData = JSON.parse(authenticationDataString);
          setIdToken(authenticationData.idToken);
          dispatch(setAuthentication(authenticationData));
        } catch (error) {
          console.error('Error parsing authentication data:', error);
        }
      }

      if (productDataString) {
        try {
          const productData = JSON.parse(productDataString);
          dispatch(setUserProduct(productData as any));
        } catch (error) {
          console.error('Error parsing product data:', error);
        }
      }

      if (subscriptionPlanString) {
        try {
          const planData = JSON.parse(subscriptionPlanString);
          dispatch(setPlans(planData as any));
        } catch (error) {
          console.error('Error parsing subscription Plans data:', error);
        }
      }

      if (chatHistoryString) {
        try {
          const chatHistory = JSON.parse(chatHistoryString);
          dispatch(setChatHistory(chatHistory as any));
        } catch (error) {
          console.error('Error parsing chat history data:', error);
        }
      }
    };

    getAuthenticationData();

    return () => {};
  }, [dispatch]);

  const editProfile = async () => {
    try {
      const colorMode = localStorage.getItem('chakra-ui-color-mode');

      console.log('CLICKED Edit profile............');
    } catch (error) {
      console.error(error);
      toast({
        title: 'Auth0 Login failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
    }
  };

  const silentlogin = async () => {
    const authenticationDataString = localStorage.getItem('authentication');
    if (authenticationDataString) {
      try {
        const authenticationData = JSON.parse(authenticationDataString);
        dispatch(setAuthentication(authenticationData));
        return;
      } catch (error) {
        console.error('Error parsing authentication data:', error);
      }
    }
  };

  const login = async () => {
    try {
      const colorMode = localStorage.getItem('chakra-ui-color-mode');

      console.log('in LOGIN');
      loginWithRedirect();
    } catch (error) {
      console.error(error);
      toast({
        title: 'Auth0 Login failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
    }
  };

  const logOut = async () => {
    try {
      logout({ logoutParams: { returnTo: window.location.origin } });
      // await instance.logoutRedirect();
      setIdToken('');
      toast({
        title: 'Logout successful',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
      history.push('/');
    } catch (error) {
      console.error(error);
    }
  };

  const getToken = async (): Promise<string> => {
    try {
      const formData = new URLSearchParams();
      formData.append('grant_type', 'password');
      formData.append('username', process.env.REACT_APP_LLM_API_ADMIN_NAME!);
      formData.append(
        'password',
        process.env.REACT_APP_LLM_API_ADMIN_PASSWORD!
      );

      const authResponse = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/token`,
        formData
      );
      return authResponse.data.access_token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  const fetchUserProduct = async (): Promise<any> => {
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/product/user-products`;
      if (!profile?.userId) {
        console.error('No User id found');
        login();
        return;
      }
      const token = await getToken();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ user_id: profile?.userId }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      let found_product = false;
      if (data && data.data.length > 0) {
        data.data.forEach((category: any) => {
          category.products.forEach((product: any) => {
            if (product.active) {
              found_product = true;
            }
          });
        });
      }
      // return data.data;

      const productData = {
        hasProduct: found_product,
        product: data.data,
      };
      console.log('Active Product:', productData);

      sessionStorage.setItem('product', JSON.stringify(productData));

      dispatch(setUserProduct(productData as any));
    } catch (error) {
      console.error('Error fetching data:', error);
      return false;
    }
  };

  const fetchSubscriptionPlan = async () => {
    try {
      const allPlansApiURL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/get-all-subscription-plans`;
      const token = await getToken();
      const response = await fetch(allPlansApiURL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();

      sessionStorage.setItem('plan', JSON.stringify(data.data));
      // setSubscriptionPlans(data.data);

      // Fetch user's active subscriptions if profile data is available
      dispatch(setPlans(data.data as any));
      return data.data;
      // if (profile?.idToken && profile?.userId) {
      //   const user_plans = await getUserActiveSubscriptions(profile.userId);
      //   // setUserActiveSubscriptions(user_plans);
      // }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getUserActiveSubscriptions = async (userId: number) => {
    const userActiveSubscriptionsApiURL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/get-user-active-subscriptions`;

    try {
      const token = await getToken();
      const response = await axios.post(
        userActiveSubscriptionsApiURL,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        return response.data.data;
      } else {
        throw new Error(
          response.data.message || 'Failed to fetch user active subscriptions'
        );
      }
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message ||
          'Failed to fetch user active subscriptions'
      );
    }
  };

  const fetchUserChatHistory = async (clear = false) => {
    try {
      if (clear) {
        console.log('cleared ITEM');

        dispatch(resetChatHistory());
        console.log('cleared ITEM --1 ');
        sessionStorage.removeItem('chat');
        console.log('cleared ITEM 2 ');
      }
      console.log('cleared ITEM ---0005 ');
      // setLoading(true);
      const getSessionURL = `${process.env.REACT_APP_LLM_API_URL}/get-all-chat-history`;
      let pageNumber;
      if (chat?.pageId) {
        pageNumber = chat?.pageId + 1;
      } else {
        pageNumber = 1;
      }
      if (clear) {
        pageNumber = 1;
      }
      console.log('cleared ITEM 3 ');
      const requestBody = {
        partner_name: 'federal_banking_compliance',
        user_id: profile?.userId,
        page_id: pageNumber,
      };
      const token = await getToken();
      const response = await axios.post(getSessionURL, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sessions = response.data;

      const newItems = sessions.map((session: any) => {
        const titleContent = session.content[0].content;
        const title =
          titleContent.length > 25
            ? `${titleContent.substring(0, 25)}...`
            : titleContent;

        return {
          id: session.session_id,
          title: titleContent,
          date: session.create_date.split('T')[0],
          solution: session.solution,
        };
      });
      let chatItem;
      if (clear) {
        chatItem = newItems;
      } else {
        chatItem = chat?.messages.concat(newItems);
      }
      const chatData = {
        pageId: pageNumber,
        messages: chatItem,
        isLastPage: sessions[0]?.is_last_page,
      };

      dispatch(setChatHistory(chatData as any));

      // setData((prevData) =>
      //   page === 1 ? newItems : [...prevData, ...newItems]
      // );
      // setHasMore(!sessions[0]?.is_last_page);
      // setShowHistoryAccordion(true);
      sessionStorage.setItem('chat', JSON.stringify(chatData));

      return { items: newItems, isLast: sessions[0]?.is_last_page };
    } catch (error) {
      console.error('Error fetching user chat history:', error);
    }
  };

  return {
    idToken,
    login,
    logOut,
    editProfile,
    getUserActiveSubscriptions,
    getToken,
    silentlogin,
    fetchUserProduct,
    fetchSubscriptionPlan,
    fetchUserChatHistory,
  };
};

export default useAuth;
