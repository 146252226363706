import { Flex, useBreakpointValue, useToast } from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SEOComponent from 'seo';
import { setInputMessage } from 'store/reducers/inputMessage';
import { addMessage, clearMessages } from 'store/reducers/messages';
import { newSessionFound } from 'store/reducers/sessionSlice';
import { RootState } from 'store/store';
import { v4 as uuidv4 } from 'uuid';
import LogInModal from 'views/shared/component/LogInModal';
import AIModel from './AIModel';
import Divider from './Divider';
import ChatFooter from './Footer';
import Messages from './Messages';
import { extractAndRemoveUUID, removeEndMarker } from './utils';

export default function Chat() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const initialTab = parseInt(queryParams.get('tab') || '1', 10);
  const [tabValue, setTabValue] = useState(initialTab); // Manage tabValue in state
  const [loading, setLoading] = useState(false);
  const { login, getToken } = useAuth();
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.messages);
  const inputMessage = useSelector((state: RootState) => state.inputMessage);
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [newChat, setIsNewChat] = useState(false);
  const toast = useToast();
  const ws = useRef<WebSocket | null>(null);

  const [sessionId, setSessionId] = useState(null);

  const handleSendWSMessage = () => {
    if (!ws.current) return;
    let intervalId: number | null = null;
    const bufferArray: string[] = [];
    ws.current.onmessage = (event) => {
      if (sessionStorage.getItem('session') === sessionId) {
        const message = event.data;
        let {
          uuid: answer_uuid,
          cleanedText,
          prompt,
        } = extractAndRemoveUUID(message);

        if (cleanedText.endsWith('<END>')) {
          cleanedText = removeEndMarker(cleanedText);
          setLoading(false);
          if (newChat) {
            dispatch(newSessionFound());
            setIsNewChat(false);
          }
        }

        bufferArray.push(cleanedText);

        if (intervalId === null) {
          intervalId = window.setInterval(() => {
            if (bufferArray.length > 0) {
              const textToRender = bufferArray.shift();
              if (textToRender !== undefined) {
                dispatch(
                  addMessage({
                    from: 'computer',
                    text: textToRender,
                    uu_id: answer_uuid,
                  })
                );
              }
            } else {
              clearInterval(intervalId);
              intervalId = null;
            }
          }, 33); // Adjust the interval duration as needed
        }
      }
    };
  };

  const handleQestionSendMessage = async () => {
    connectWebSocket();
    if (
      !ws.current ||
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      return;
    }
    // console.log('messageToSend ===========', messageToSend);

    const message = inputMessage;

    if (!message.trim().length) {
      return;
    }

    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    const tabFromSession = parseInt(sessionStorage.getItem('activeTab') || '1');

    let partnerId, partner, persona;
    if (tabFromSession === 2) {
      partnerId = process.env.REACT_APP_STATE_PARTNER_ID;
      partner = process.env.REACT_APP_STATE_PARTNER;
      persona = process.env.REACT_APP_STATE_PERSONA;
    } else if (tabFromSession === 1) {
      partnerId = process.env.REACT_APP_PARTNER_ID;
      partner = process.env.REACT_APP_PARTNER;
      persona = process.env.REACT_APP_PERSONA;
    } else if (tabFromSession === 3) {
      partnerId = process.env.REACT_APP_CANADA_PARTNER_ID;
      partner = process.env.REACT_APP_CANADA_PARTNER;
      persona = process.env.REACT_APP_CANADA_PERSONA;
    }

    if (
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      toast({
        title: 'WebSocket Disconnected',
        description: 'Reconnecting...',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      connectWebSocket();
      return;
    }

    const uu_id = uuidv4().toString();
    dispatch(addMessage({ from: 'me', text: message }));
    dispatch(setInputMessage(''));
    dispatch(addMessage({ from: 'computer', text: ' ', uu_id: uu_id }));
    const token = await getToken();
    const requestBody = JSON.stringify({
      partner_id: partnerId,
      partner: partner,
      persona: persona,
      UUId: uu_id,
      question: message,
      user_id: profile?.userId,
      session_id: sessionId,
      access_token: token,
    });
    // console.log('requestBody:', requestBody);
    ws.current.send(requestBody);
    handleSendWSMessage();
  };

  const handleSendMessage = async (messageToSend?: string) => {
    connectWebSocket();
    if (
      !ws.current ||
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      return;
    }

    const message = messageToSend || inputMessage;

    if (!message.trim().length) {
      return;
    }

    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    const tabFromSession = parseInt(sessionStorage.getItem('activeTab') || '1');

    let partnerId, partner, persona;
    if (tabFromSession === 2) {
      partnerId = process.env.REACT_APP_STATE_PARTNER_ID;
      partner = process.env.REACT_APP_STATE_PARTNER;
      persona = process.env.REACT_APP_STATE_PERSONA;
    } else if (tabFromSession === 1) {
      partnerId = process.env.REACT_APP_PARTNER_ID;
      partner = process.env.REACT_APP_PARTNER;
      persona = process.env.REACT_APP_PERSONA;
    } else if (tabFromSession === 3) {
      partnerId = process.env.REACT_APP_CANADA_PARTNER_ID;
      partner = process.env.REACT_APP_CANADA_PARTNER;
      persona = process.env.REACT_APP_CANADA_PERSONA;
    }

    if (
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      toast({
        title: 'WebSocket Disconnected',
        description: 'Reconnecting...',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      connectWebSocket();
      return;
    }

    const uu_id = uuidv4().toString();
    dispatch(addMessage({ from: 'me', text: message }));
    dispatch(setInputMessage(''));
    dispatch(addMessage({ from: 'computer', text: ' ', uu_id: uu_id }));
    const token = await getToken();
    const requestBody = JSON.stringify({
      partner_id: partnerId,
      partner: partner,
      persona: persona,
      UUId: uu_id,
      question: message,
      user_id: profile?.userId,
      session_id: sessionId,
      access_token: token,
    });
    console.log('requestBody:', requestBody);
    ws.current.send(requestBody);
    handleSendWSMessage();
  };

  const chatHeight = useBreakpointValue({
    base: '80vh',
    md: '85vh',
    xl: '78vh',
    '2xl': '82vh',
  });

  const connectWebSocket = () => {
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-get-answer`
    );

    newWs.onopen = () => {
      console.log('WebSocket connected');
      ws.current = newWs;
    };

    newWs.onclose = () => {
      console.log('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
      toast({
        title: 'Error connecting websocket',
        description: 'Failed to connect websocket. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !ws.current) {
        // You may want to reconnect WebSocket or perform other actions here
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const getSessionData = async (session_id: string) => {
    const getSessionURL = `${process.env.REACT_APP_LLM_API_URL}/get-session-data`;
    const requestBody = {
      session_id: session_id,
    };
    const token = await getToken();
    const response = await axios.post(getSessionURL, requestBody, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };

  const fetchData = async (sessionId: any, dispatch: any) => {
    if (sessionId) {
      try {
        const old_chat = await getSessionData(sessionId);
        old_chat.content.forEach((message: any) => {
          const { role, content } = message;
          if (role === 'user') {
            dispatch(addMessage({ from: 'me', text: content }));
          } else if (role === 'assistant') {
            dispatch(addMessage({ from: 'computer', text: content }));
          }
        });
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    }
  };

  const clearIntervalFunc = () => {
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };
  const startNewSession = () => {
    const newSessionId = uuidv4();
    setIsNewChat(true);
    sessionStorage.setItem('session', newSessionId);
    setSessionId(newSessionId);
  };

  const closeCurrentWSConnection = () => {
    if (ws.current) {
      ws.current.close();
      ws.current.onmessage = null;
      ws.current = null;
    }
  };

  const handleTabChange = (newTab: number) => {
    // console.log('CLINKED ON handleTabChange');

    sessionStorage.setItem('activeTab', newTab.toString());
    closeCurrentWSConnection();
    clearIntervalFunc();
    startNewSession();
    dispatch(clearMessages());
    setTabValue(newTab);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionIdFromURL = queryParams.get('sessionId');
    const tabValueFromURL = parseInt(queryParams.get('tab') || '1', 10);
    dispatch(clearMessages());

    if (sessionIdFromURL) {
      dispatch(setInputMessage(''));
      setSessionId(sessionIdFromURL);
      setTabValue(tabValueFromURL);
      sessionStorage.setItem('session', sessionIdFromURL);
      fetchData(sessionIdFromURL, dispatch);
    } else {
      startNewSession();
    }
    closeCurrentWSConnection();
    clearIntervalFunc();
    connectWebSocket();
  }, [location]);

  return (
    <div style={{ marginTop: '40px' }}>
      <SEOComponent
        title="Nucomply - Compliance Expert Assistant"
        description="Experience the future of compliance management with nucomply, and empower your institution with the tools needed to confidently navigate the complex regulatory landscape."
        canonical="/compliance/chat"
      />

      <AIModel tab={tabValue} onTabChange={handleTabChange} />
      <Flex w="100%" h={chatHeight} justify="center" align="center">
        <Flex
          w={['95%', '90%', '85%', '80%', '70%']}
          mx="auto"
          maxW="58rem"
          h="100%"
          flexDir="column"
        >
          <Messages handleSendMessage={handleSendMessage} tabValue={tabValue} />
          <Divider />
          <ChatFooter
            handleSendMessage={handleQestionSendMessage}
            loading={loading}
          />
        </Flex>
      </Flex>

      {!profile?.userId && <LogInModal />}
    </div>
  );
}
