import { createSlice } from '@reduxjs/toolkit';

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: [],
  reducers: {
    addMessage: (state, action) => {
      if (action.payload.from === 'me') {
        state.push(action.payload);
      } else if (action.payload.from === 'computer') {
        if (state.length > 0 && state[state.length - 1].from === 'me') {
          state.push(action.payload);
        } else {
          const index = state.findIndex(
            (message) => message.uu_id === action.payload.uu_id
          );
          if (index !== -1) {
            state[index].text = action.payload.text;
          } else {
            // In case we receive a computer message without a corresponding uu_id
            state.push(action.payload);
          }
        }
      }
    },
    clearMessages: (state) => {
      state.splice(0, state.length);
    },
  },
});

export const { addMessage, clearMessages } = messagesSlice.actions;

export default messagesSlice.reducer;
