import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const PaymentSuccessAlert = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const { getToken } = useAuth();

  const extractUUIdFromURL = () => {
    const urlParts = location.pathname.split('/');
    const uuId = urlParts[urlParts.length - 1];
    return uuId;
  };

  useEffect(() => {
    const fetchData = async () => {
      const uuId = extractUUIdFromURL();
      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/success/${uuId}`;

      try {
        const token = await getToken();
        const response = await fetch(apiUrl,{
        headers: { Authorization: `Bearer ${token}` }
      });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log(responseData);

        setLoading(false);
        setSuccess(responseData.success);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setLoading(false);
        setSuccess(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (!loading) {
        if (success) {
          // Redirect to /compliance/pricing on success
          history.push('/compliance/pricing');
        } else {
          // Redirect to /compliance/pricing on error
          // You can also show a message here before redirecting
          // For example: alert("Payment failed. We will get back to you soon.");
          history.push('/compliance/pricing');
        }
      }
    }, 3000); // 3 seconds

    return () => clearTimeout(redirectTimeout); // Clear timeout on unmount
  }, [loading, success, history]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      {loading ? (
        <Alert
          status='info'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          width="400px"
          borderRadius="md"
          boxShadow="lg"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Loading...
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Please wait while we process your payment.
          </AlertDescription>
        </Alert>
      ) : success ? (
        <Alert
          status='success'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          width="400px"
          borderRadius="md"
          boxShadow="lg"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Thank you for your payment!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            We've successfully received your payment.
          </AlertDescription>
        </Alert>
      ) : (
        <Alert
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          width="400px"
          borderRadius="md"
          boxShadow="lg"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Invalid URL
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            The URL you provided is invalid. Please try again.
          </AlertDescription>
        </Alert>
      )}
    </Box>
  );
};

export default PaymentSuccessAlert;
