import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from 'store/store';
import theme from 'theme/theme';
import MainPage from 'mainPage';

const App = () => (
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <MainPage />
      </Provider>
    </React.StrictMode>
  </ChakraProvider>
);

export default App;