import { FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';

interface UploadTagMultiSelectProps {
  selectedOptions: string[];
  onSelectionChange: (selectedOptions: string[]) => void;
  isEditing: boolean;
  options: { value: string; label: string }[];
  setOptions: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >;
}

const UploadTagMultiSelect: React.FC<UploadTagMultiSelectProps> = ({
  selectedOptions,
  onSelectionChange,
  isEditing,
  options,
  setOptions,
}) => {
  const { getToken } = useAuth();
  const toast = useToast();
  const [selectedItems, setSelectedItems] = useState<
    { value: string; label: string }[]
  >(() => selectedOptions.map((option) => ({ value: option, label: option })));

  useEffect(() => {
    setSelectedItems(
      selectedOptions.map((option) => ({ value: option, label: option }))
    );
  }, []);

  const handleChange = (newValue: any, actionMeta: any, parent: string) => {
    const updatedItems = selectedItems.filter(
      (item) =>
        !groupedOptions[parent].some(
          (option: any) => option.value === item.value
        )
    );
    const newItems = newValue.map((item: any) => ({
      value: item.value,
      label: item.label,
    }));
    const allSelectedItems = [...updatedItems, ...newItems];

    setSelectedItems(allSelectedItems);
    onSelectionChange(allSelectedItems.map((item: any) => item.value));
  };

  interface Option {
    value: string;
    label: string;
  }

  const transformOptions = (options: Option[]): any[] => {
    const groupedOptions = options.reduce((acc, option) => {
      const existingLabel = acc.find((item) => item.label === option.label);
      if (existingLabel) {
        existingLabel.options.push({
          value: option.value,
          label: option.value,
        });
      } else {
        acc.push({
          label: option.label,
          options: [{ value: option.value, label: option.value }],
        });
      }
      return acc;
    }, []);

    return groupedOptions;
  };

  const transformedOptions = transformOptions(options);

  console.log('===================== UploadTagMultiSelect TAGS');

  console.log(transformedOptions);

  const groupOptionsByParent = (options: any) => {
    return options.reduce((acc: any, option: any) => {
      const parent = option.parent_tag_type_name || option.label; // Use label if parent_tag_type_name is null
      const key =
        parent === option.label ? parent : `${parent}: ${option.label}`; // Create a unique key for each parent-label pair

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({ value: option.value, label: option.value });

      return acc;
    }, {} as { [key: string]: { value: string; label: string }[] });
  };

  const groupedOptions = groupOptionsByParent(options);
  console.log('AFTER ========');
  console.log(groupedOptions);

  return (
    <>
      {Object.keys(groupedOptions).map((parent, index) => (
        <FormControl p={4} key={index}>
          <FormLabel>{parent}</FormLabel>
          <Select
            isMulti
            isDisabled={!isEditing}
            selectedOptionStyle="check"
            name="tags"
            options={groupedOptions[parent]}
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta, parent)
            }
            placeholder={`Select ${parent} tag...`}
            closeMenuOnSelect={false}
            chakraStyles={{
              dropdownIndicator: (provided) => ({
                ...provided,
                bg: 'transparent',
                px: 2,
                cursor: 'inherit',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
              groupHeading: (provided) => ({
                ...provided,
                fontWeight: 'bold',
                color: 'gray',
              }),
              option: (provided) => ({
                ...provided,
                paddingLeft: '30px',
              }),
            }}
          />
        </FormControl>
      ))}
    </>
  );
};

export default UploadTagMultiSelect;
