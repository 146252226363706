import { Button, Flex, Input, useColorModeValue } from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInputMessage } from 'store/reducers/inputMessage';
import { RootState } from 'store/store';

const ChatFooter: React.FC<any> = ({ handleSendMessage, loading }) => {
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const inputColor = useColorModeValue('navy.700', 'white');

  const { login } = useAuth();
  const handleLogIn = () => {
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
  };
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const inputMessage = useSelector((state: RootState) => state.inputMessage);
  const dispatch = useDispatch();
  let placeholderMsg = '';
  if (!profile?.idToken) {
    placeholderMsg = 'Login to ask your first question.';
  } else {
    placeholderMsg = 'Type your message here...';
  }
  return (
    <Flex w="100%" mt="5">
      <Input
        value={inputMessage}
        minH="54px"
        h="100%"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="45px"
        p="15px 20px"
        me="10px"
        fontSize="sm"
        fontWeight="500"
        _focus={{ borderColor: 'none' }}
        color={inputColor}
        _placeholder={placeholderColor}
        placeholder={placeholderMsg}
        onChange={(e) => {
          dispatch(setInputMessage(e.target.value));
        }}
        onClick={() => {
          if (!profile?.idToken) {
            console.log('calling login');
            handleLogIn(); // Call handleLogIn when not logged in
          }
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !loading) {
            handleSendMessage();
          }
        }}
      />

      <Button
        variant="primary"
        py="20px"
        px="16px"
        fontSize="sm"
        borderRadius="45px"
        ms="auto"
        w={{ base: '160px', md: '210px' }}
        h="54px"
        _hover={{
          boxShadow: '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
          bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
          _disabled: {
            bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
          },
        }}
        onClick={handleSendMessage}
        // isLoading={loading} // Add isLoading prop
        // disabled={!profile?.idToken || inputMessage.trim().length <= 0 || loading} // Disable button when loading or no idToken
      >
        {loading ? 'Sending...' : 'Send'}
      </Button>
    </Flex>
  );
};

export default ChatFooter;
