import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import TagMultiSelect from './TagMultiSelect';

interface Tag {
  persona_id: number;
  tag: string;
  tag_type_name: string;
  priority: number;
}

const ExploreData = () => {
  const { fileName }: any = useParams();
  const { personaId }: any = useParams();
  const { persona }: any = useParams();
  const { partner }: any = useParams();
  console.log('personaId from perems', personaId);
  const [fileData, setFileData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [editableContent, setEditableContent] = useState<
    Record<string, string>
  >({});
  const [charCount, setCharCount] = useState<Record<string, number>>({});
  // const [isEditing, setIsEditing] = useState<string | null>(null); // Only one editing ID
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); // Loading state for delete operation
  const toast = useToast();
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login, getToken } = useAuth();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
  const cancelRef = useRef<any>();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  ); // Example state for file data
  const [isEditingContent, setIsEditingContent] = useState<number | null>(null); // State for tracking content editing
  const [isEditingTags, setIsEditingTags] = useState<number | null>(null);

  const onCloseAlert = () => setIsAlertOpen(false);

  const handleSelectionChange = (newSelectedOptions: string[]) => {
    // setSelectedOptions(newSelectedOptions);
    console.log('Get new data on select');
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setFileData(null);
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/explore-data?partner=${process.env.REACT_APP_PARTNER}&persona=${process.env.REACT_APP_PERSONA}&filename=${fileName}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setFileData(response.data);
      setLoading(false);

      // Initialize editable content and char count for each file
      const initialContent = response.data.file_data.reduce(
        (acc: any, file: any) => {
          acc[file.id] = file.content;
          return acc;
        },
        {}
      );
      setEditableContent(initialContent);

      const initialCharCount = response.data.file_data.reduce(
        (acc: any, file: any) => {
          acc[file.id] = file.content.length;
          return acc;
        },
        {}
      );
      setCharCount(initialCharCount);
    } catch (error) {
      console.error('Error fetching file data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!profile?.idToken || !profile?.userId) {
      login();
    }
    if (!profile?.isAdmin) {
      console.log('NOT ADMIN');
    } else {
      console.log('ADMIN');

      fetchData();
    }
  }, []);

  const handleEditContent = (id: string, content: string) => {
    if (content.length <= 1000) {
      setEditableContent({
        ...editableContent,
        [id]: content,
      });
      setCharCount({
        ...charCount,
        [id]: content.length,
      });
    }
  };

  const handleUpdateContent = async (file: any) => {
    const updatedContent = editableContent[file?.id];

    try {
      const updatePayload = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        filename: file?.sourcefile,
        id: file?.id,
        content: updatedContent,
        sourcepage: file?.sourcepage,
      };

      // Set loading state to true while updating
      setLoading(true);
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk`,
        updatePayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data === true) {
        toast({
          title: 'Update Successful',
          description: 'The file chunk has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          // variant: 'subtle',
        });

        // Refetch file data after successful update
        fetchData();
        // setIsEditing(null);
      } else {
        toast({
          title: 'Update Failed',
          description:
            'Failed to update the file chunk. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          // variant: 'subtle',
        });
        setLoading(false); // Set loading state to false if update fails
      }
    } catch (error) {
      console.error('Error updating file chunk:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while updating the file chunk. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
      setLoading(false); // Set loading state to false if update fails
    }
  };

  const handleDeleteContent = async () => {
    if (!fileIdToDelete) return;

    setIsDeleteLoading(true); // Set delete loading state to true

    try {
      console.log('deleting file id: ', fileIdToDelete);

      const requestBody = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        file_id: fileIdToDelete,
        auth: profile?.user?.sub,
      };

      const token = await getToken();

      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file-chunk`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );

      console.log(';STEP 1  ********');

      if (response.data.delete === true) {
        console.log(';STEP IFFFFFF  ********');
        toast({
          title: 'Delete Successful',
          description: 'The file chunk has been successfully deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.log(';STEP IERROR  ********');
      console.error('Error deleting file chunk:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while deleting the file chunk. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      console.log(';STEP FINAL  ********');
      fetchData();
      setIsDeleteLoading(false); // Set delete loading state to false
      onCloseAlert();
      setFileIdToDelete(null);
    }
  };

  const handleGoBack = () => {
    history.goBack(); // Navigate back to the previous page
  };
  const handleTagChange = (fileId: string, newTags: string[]) => {
    console.log('clicked change');
    // setFileTags({ ...fileTags, [fileId]: newTags });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_LLM_API_URL}/get-persona-tags/${personaId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data: any = await response.json();

      const fetchedTags = data.map((tag: any) => ({
        priority: tag.priority,
        value: tag.tag,
        label: tag.tag_type_name,
        parent_tag_type_id: tag.parent_tag_type_id,
        parent_tag_type_name: tag.parent_tag_type_name,
      }));
      console.log('===================== FETCHED TAGS');
      console.log(fetchedTags);
      setOptions(fetchedTags);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  return (
    <Box p={8} maxW="7xl" mx="auto">
      <IconButton
        icon={<ArrowBackIcon />}
        aria-label="Back"
        variant="outline"
        colorScheme="blue"
        onClick={handleGoBack}
        mb={4}
      />
      <Text fontSize="3xl" textAlign="center" mb={6}>
        Explore Data: {fileName}
      </Text>

      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Box>
          {fileData?.file_data.map((file: any) => (
            <Box key={file.id} mb={4}>
              <Box p={6} borderWidth="1px" borderRadius="10px">
                <Text fontWeight="bold" mb={2}>
                  ID: {file.id}
                </Text>
                <Text fontWeight="bold" mb={1}>
                  Item Details:
                </Text>

                {isEditingContent === file.id ? (
                  <>
                    <Textarea
                      border="2px solid"
                      borderRadius="10px"
                      p="15px 20px"
                      mb="28px"
                      minH="150px"
                      fontWeight="500"
                      value={editableContent[file.id] || ''}
                      onChange={(e) =>
                        handleEditContent(file.id, e.target.value)
                      }
                    />
                    <ButtonGroup>
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          handleUpdateContent(file);
                          setIsEditingContent(null);
                        }}
                      >
                        Save Content
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          setIsAlertOpen(true);
                          setFileIdToDelete(file.id);
                        }}
                      >
                        Delete
                      </Button>
                      <Button onClick={() => setIsEditingContent(null)}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </>
                ) : (
                  <>
                    <Box
                      p={4}
                      borderRadius="10px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      lineHeight="1.2em"
                    >
                      {file.content}
                    </Box>
                  </>
                )}

                <SimpleGrid columns={2} spacing={4} mt={4}>
                  <TagMultiSelect
                    partner={partner}
                    persona={persona}
                    fileName={file.sourcefile}
                    sourcepage={file.sourcepage}
                    id={file.id}
                    selectedOptions={file.tags}
                    onSelectionChange={handleSelectionChange}
                    isEditing={isEditingTags === file.id}
                    options={options}
                    setOptions={setOptions}
                  />
                </SimpleGrid>

                <ButtonGroup mt={4}>
                  {isEditingTags === file.id ? (
                    <Button onClick={() => setIsEditingTags(null)}>
                      Cancel
                    </Button>
                  ) : (
                    <>
                      {isEditingTags !== file.id && (
                        <>
                          {isEditingContent !== file.id && (
                            <Button
                              colorScheme="blue"
                              onClick={() => {
                                setIsEditingContent(file.id);
                                setIsEditingTags(null); // Disable tag editing when editing content
                              }}
                            >
                              Edit Content
                            </Button>
                          )}
                        </>
                      )}
                      {isEditingContent !== file.id && (
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            setIsEditingTags(file.id);
                            setIsEditingContent(null); // Disable content editing when editing tags
                          }}
                        >
                          Edit Tags
                        </Button>
                      )}
                    </>
                  )}
                </ButtonGroup>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete File Chunk
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this file chunk? This action
              cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDeleteContent}
                ml={3}
                isLoading={isDeleteLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ExploreData;
