// Chakra imports
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import TemplateCard from 'components/card/TemplateCard';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SEOComponent from 'seo';
import { RootState } from 'store/store';

export default function Settings() {
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login, getUserActiveSubscriptions } = useAuth();

  const [userPlans, setUserPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasPremiumPlan, setHasPremiumPlan] = useState(false);
  const [hasEnterprisePlan, setHasEnterprisePlan] = useState(false);

  useEffect(() => {
    async function fetchUserPlans() {
      if (profile?.userId) {
        const plans = await getUserActiveSubscriptions(profile.userId);
        setUserPlans(plans);
        const premium = plans.some((plan: any) => plan.plan_name === 'Premium');
        setHasPremiumPlan(premium);
        const enterprise = plans.some(
          (plan: any) => plan.plan_name === 'Enterprise'
        );
        setHasEnterprisePlan(enterprise);
      } else {
        setHasPremiumPlan(false);
        setHasEnterprisePlan(false);
      }
      setLoading(false);
    }
    fetchUserPlans();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Optional: Add a loading state
  }

  return (
    <>
      <SEOComponent
        title="Nucomply-Solutions"
        description="Banking Compliance Expert Provided Solutions"
        canonical="/compliance/solutions"
      />
      <Flex mt={{ base: '-20px', md: '100px' }}>
        <Box mt={{ base: '70px', md: '0px', xl: '0px' }}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing="20px">
            <TemplateCard
              link="/compliance/chat?tab=1"
              src="/img/solutions/askQuestionFederal.png"
              name="Ask a Question - US Federal"
              description="Ask questions of an AI Banking Compliance Expert based on federal regulatory content."
            />
            <TemplateCard
              link="/compliance/chat?tab=2"
              src="/img/solutions/askQuestionFederalnState.png"
              name="Ask a Question - Federal & State"
              description="Ask questions of an AI Banking Compliance Expert based on federal & state regulatory content."
            />
            <TemplateCard
              link="/compliance/chat?tab=3"
              src="/img/solutions/askQuestionCanada.png"
              name="Ask a Question - Canada"
              description="Ask questions of an AI Banking Compliance Expert based on Canadian regulatory content."
              locked={!hasEnterprisePlan}
            />

            <TemplateCard
              link="/compliance/email-review"
              src="/img/solutions/emailReview.png"
              name="Email Review (Beta)"
              description="Review outbound email to ensure it meets compliance standards."
            />
            <TemplateCard
              link="/compliance/marketing-review"
              src="/img/solutions/marketingReview.png"
              name="Marketing Review (Beta)"
              description="Draft or review a policy for your financial institution."
              // locked={!(hasPremiumPlan || hasEnterprisePlan)}
            />
            <TemplateCard
              link="/compliance/policy-review"
              src="/img/solutions/RagulatoryChangeReview.png"
              name="Policy Review (Beta)"
              description="Draft or review a policy for your financial institution."
              // locked={!(hasPremiumPlan || hasEnterprisePlan)}
            />
            <TemplateCard
              link="/compliance/whole-body-testing"
              src="/img/solutions/wholeBodyTesting.png"
              name="Whole Body Testing"
              description="Complete testing of official documents for compliance."
              locked={!hasEnterprisePlan}
            />
            <TemplateCard
              link="/compliance/organization-complaints-management"
              src="/img/solutions/wholeBodyTesting.png"
              name="Organization Complaints Management"
              description="Draft complaint response and manage official reporting."
              locked={!hasEnterprisePlan}
            />
            <TemplateCard
              link="/compliance/regulatory-reporting-assistant"
              src="/img/solutions/wholeBodyTesting.png"
              name="Regulatory Reporting Assistant"
              description="Help compile narrative text for regulatory reporting."
              locked={!hasEnterprisePlan}
            />
            <TemplateCard
              link="/compliance/policy-generator"
              src="/img/solutions/policyGenerator.png"
              name="Policy Generator"
              description="Draft or review a policy for your financial institution."
              comingSoon={true}
            />
            <TemplateCard
              link="/compliance/policy-review"
              src="/img/solutions/policyReview.png"
              name="Policy Review"
              description="Draft or review a policy for your financial institution."
              comingSoon={true}
            />
            <TemplateCard
              link="/compliance/regulatory-change-review"
              src="/img/solutions/RagulatoryChangeReview.png"
              name="Regulatory Change Review"
              description="Review and analyze the impact of regulatory changes."
              comingSoon={true}
            />
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  );
}
