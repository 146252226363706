import ReactDOM from 'react-dom';
import '../src/styles/App.css';
import '../src/styles/Contact.css';
import '../src/styles/MiniCalendar.css';
import '../src/styles/Plugins.css';
import App from './app';
import './assets/css/App.css';
import registerServiceWorker from './registerServiceWorker';
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
