'use client';
/* eslint-disable */

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import complianceData from './complianceData.json'; // Import the JSON data

import './MarketingReview.css';
import SEOComponent from 'seo';
export default function MarketingReview() {
  const [body, setBody] = useState<string>('');
  const [selectedCompliance, setSelectedCompliance] = useState<string[]>([]);
  const [complianceList, setComplianceList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [assistantResponse, setAssistantResponse] = useState<any>(null);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [wsLoading, setWsLoading] = useState<boolean>(false);

  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const toast = useToast();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login, getToken } = useAuth();
  useEffect(() => {
    // Set the compliance list from the imported JSON data

    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
    setComplianceList(complianceData);
  }, []);

  function removeEndMarker(input: string): string {
    const endMarker = '<END>';
    if (input.endsWith(endMarker)) {
      return input.substring(0, input.length - endMarker.length);
    }
    return input;
  }

  const handleSendWSMessage = () => {
    if (!ws) return;
    let intervalId: number | null = null;
    const bufferArray: string[] = [];

    ws.onmessage = (event) => {
      setLoading(false);
      const message = event.data;
      let cleanText = message;

      if (message.endsWith('<END>')) {
        cleanText = removeEndMarker(cleanText);
        setLoading(false);
        setWsLoading(false);
      }

      bufferArray.push(cleanText);

      if (intervalId === null) {
        intervalId = window.setInterval(() => {
          if (bufferArray.length > 0) {
            const textToRender = bufferArray.shift();
            setAssistantResponse(textToRender);
          } else {
            clearInterval(intervalId);
            intervalId = null;
          }
        }, 33); // Adjust the interval duration as needed
      }
    };
  };

  const handleReviewMarketing = async () => {
    if (!ws) return;
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    if (!body) {
      toast({
        title: 'Invalid Body',
        description: 'Please enter your body.',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
      return;
    }

    if (
      ws.readyState === WebSocket.CLOSING ||
      ws.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      // Here you could add logic to reconnect the WebSocket if necessary
      toast({
        title: 'WebSocket Disconnected',
        description: 'Reconnecting...',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });

      // Attempt to reconnect (ensure your connectWebSocket function handles reconnection properly)
      connectWebSocket();
      return;
    }

    setAssistantResponse(null);
    setLoading(true);
    setWsLoading(true);

    const selectedComplianceDetails = selectedCompliance.map((title) => {
      const compliance = complianceList.find((item) => item.title === title);
      return {
        title: compliance.title,
        descriptions: compliance.descriptions,
      };
    });
    const token = await getToken();
    ws.send(
      JSON.stringify({
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_MARKETING_REVIEW_PERSONA,
        user_id: profile?.userId,
        body: body,
        checklist_array: selectedComplianceDetails,
        access_token: token,
      })
    );
    handleSendWSMessage();
  };

  const handleBodyChange = (event: any) => setBody(event.target.value);
  const handleComplianceChange = (value: string[]) =>
    setSelectedCompliance(value);

  const handleSelectAll = () =>
    setSelectedCompliance(complianceList.map((item) => item.title));
  const handleRemoveAll = () => setSelectedCompliance([]);

  const connectWebSocket = () => {
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-get-marketing-review`
    );
    newWs.onopen = () => {
      console.log('WebSocket connected');
      setWs(newWs);
    };
    newWs.onclose = () => {
      console.log('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
      toast({
        title: 'Error connecting websocket',
        description: 'Failed to connect websocket. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        // variant: 'subtle',
      });
    };

    return () => {
      // Close WebSocket connection on component unmount
      if (ws) {
        ws.close();
      }
    };
  };

  useEffect(() => {
    if (!ws) {
      connectWebSocket();
    }

    const handleVisibilityChange = () => {
      console.log('document.visibilityState  ============');
      console.log(document.visibilityState);

      if (document.visibilityState === 'visible' && !ws) {
        console.log('visible  ============');
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [ws]);
  return (
    <> <SEOComponent
    title="Nucomply- Marketing Review"
    description="Banking Compliance Expert Marketing Review"
    canonical="/compliance/marketing-review"
  />
    <div style={{ marginTop: '100px' }}>
      <Flex
        w="100%"
        direction="column"
        position="relative"
        mt={{ base: '70px', md: '0px', xl: '0px' }}
      >
        <Flex
          mx="auto"
          w={{ base: '100%', md: '100%', xl: '100%' }}
          maxW="100%"
          justify="center"
          direction={{ base: 'column', md: 'row' }}
        >
          <Card
            minW={{ base: '100%', md: '40%', xl: '476px' }}
            maxW={{ base: '100%', md: '40%', xl: '476px' }}
            h="min-content"
            me={{ base: '0px', md: '20px' }}
            mb={{ base: '20px', md: '0px' }}
          >
            <FormLabel
              display="flex"
              ms="10px"
              htmlFor={'body'}
              fontSize="md"
              color={textColor}
              letterSpacing="0px"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Body
            </FormLabel>
            <Textarea
              border="1px solid"
              borderRadius={'10px'}
              borderColor={borderColor}
              p="15px 20px"
              mb="28px"
              minH="210px"
              fontWeight="500"
              _focus={{ borderColor: 'none' }}
              color={textColor}
              placeholder="Enter the body here..."
              _placeholder={placeholderColor}
              id="body"
              onChange={handleBodyChange}
            />

            <FormLabel
              display="flex"
              ms="10px"
              htmlFor="complianceConcerns"
              fontSize="md"
              color={textColor}
              letterSpacing="0px"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Compliance Concerns
            </FormLabel>
            <Flex justifyContent="space-between" mb="10px">
              <Button size="sm" onClick={handleSelectAll}>
                Select All
              </Button>
              <Button size="sm" onClick={handleRemoveAll}>
                Remove All
              </Button>
            </Flex>
            <CheckboxGroup
              value={selectedCompliance}
              onChange={handleComplianceChange}
            >
              <Flex wrap="wrap" gap="10px">
                {complianceList.map((compliance, index) => (
                  <Checkbox
                    key={index}
                    value={compliance.title}
                    colorScheme="purple"
                    fontWeight="500"
                  >
                    {compliance.title}
                  </Checkbox>
                ))}
              </Flex>
            </CheckboxGroup>

            <Button
              py="20px"
              px="16px"
              fontSize="md"
              variant="primary"
              borderRadius="45px"
              w={{ base: '100%' }}
              mt="28px"
              h="54px"
              onClick={handleReviewMarketing}
              isLoading={wsLoading ? true : false}
              _hover={{
                boxShadow:
                  '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
                _disabled: {
                  bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
                },
              }}
            >
              Review Marketing
            </Button>
          </Card>
          <Card maxW="100%" h="100%">
            <Flex>
              <Text
                fontSize={'30px'}
                color={textColor}
                fontWeight="800"
                mb="10px"
              >
                Review
              </Text>
            </Flex>

            <Flex ml={6} justifyContent="flex-start">
              {assistantResponse && (
                <ReactMarkdown className="font-medium markdown-content">
                  {assistantResponse
                    .replace(/```markdown/g, '')
                    .replace(/```/g, '')}
                </ReactMarkdown>
              )}
            </Flex>

            {loading && (
              <Flex justifyContent="center" alignItems="center">
                <Spinner size="xl" />
              </Flex>
            )}
            <div>
              <Text color={textColor}>{/* Render API response here */}</Text>
            </div>
          </Card>
        </Flex>
      </Flex>
      {/* Add your PremiumModal component if needed */}
      {/* <PremiumModal /> */}
    </div>
    </>
  );
}
