import { Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { MdInfoOutline, MdNotificationsNone } from 'react-icons/md';
import React from 'react';

const ComingSoonMessage: React.FC = () => {
  const navbarIcon = useColorModeValue('gray.500', 'white');

  return (
    <div>
      <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
					<Icon mt='6px' as={MdInfoOutline} color={navbarIcon} w='18px' h='18px' me='10px' />
         This feature will be coming soon! We will send an announcement to all users when it is available.
      </Text>
    </div>
  );
};

export default ComingSoonMessage;
